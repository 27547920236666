<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 80 80"
    style="
      clip-rule: evenodd;
      fill-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 1;
      version: 1;
    "
    class="icon-incomplete"
  >
    <path d="M39.9 0c22 0 39.9 17.9 39.9 39.9 0 22-17.9 39.9-39.9 39.9 -22 0-39.9-17.9-39.9-39.9 0-22 17.9-39.9 39.9-39.9Zm0 6.9c18.2 0 33 14.8 33 33 0 18.2-14.8 33-33 33 -18.2 0-33-14.8-33-33 0-18.2 14.8-33 33-33Z" />
    <path d="M58.3 36.4c0-1.9-1.6-3.5-3.5-3.5l-29.9 0c-1.9 0-3.5 1.6-3.5 3.5l0 7c0 1.9 1.6 3.5 3.5 3.5l29.9 0c1.9 0 3.5-1.6 3.5-3.5l0-7Z" />
  </svg>
</template>
